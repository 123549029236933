<template>
    <div class="card">
      <div class="card-body">
        <h4 class="mb-2">
          <span class="mr-25">{{ userdetails.firstname }}</span>
          <span>{{ userdetails.lastname }} </span>
        </h4>
        <b-tabs>
          <b-tab title="Workspaces">
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Workspace</th>
                      <th scope="col">Modules</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(workspace, index) in userdetails.workspaces"
                      :key="workspace.tenant_uid"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ workspace.name }}</td>
                      <td>
                          <!-- {{ workspace.enabledUserModules }} -->
                          <b-badge variant="info" v-for="m in workspace.enabledUserModules " :key="m" class="mr-1">
                              {{ m }}
                          </b-badge>
                      </td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm"
                          @click="openEditWorkspace(workspace._id)"
                        >
                         Manage Roles
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>
          
        </b-tabs>
      </div>
      <b-sidebar
        id="edit-workspace"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="edit_workspace"
        lazy
      >
        <div class="p-2">
          <h5 class="mb-1">Edit Roles in Each Modules</h5>
          <!-- {{ module_details }} -->
          <!-- {{ modules_list }} -->
          <div v-for="item in module_details" :key="item._id">
              <!-- {{ item }} -->
              <!-- {{item.module.name}} -->
            <div
              class="d-flex justify-content-between mb-1"
             
            >
              <p>{{ item.module.name }}</p>
              <div class="d-flex align-items-center">
                 
                <div>
                <span  v-if="item.role " @click="getRoleListTenant(item.module._id,index)" style="cursor: pointer;border: 2px dotted #ccc;padding: 5px 10px;border-radius: 3px;" title="Click to change role" @blur="endEditing">{{ item.role }}</span>
                <span  v-if="item.role == null " @click="getRoleListTenant(item.module._id)" style="cursor: pointer;border: 2px dotted #ccc;padding: 5px 10px;border-radius: 3px;font-size: 12px;" title="Click to change role"  @blur="endEditing" >+</span>
              </div>
              <select
                name=""
                id=""
                class="form-control mr-1 ml-1 form-control-sm"
                style="max-width: 150px"
                @change="updateTenantModule(item.module._id,item.role)"
                v-model="item.role"
                v-if="item.module._id == module_id && isediting"
                @blur="endEditing"
              >
              <option value="null" selected>-Select Role-</option>
                <option :value="item.name" v-for="item in user_role_list" :key="item._id">{{ item.name }}</option>
              </select>
                <span @click="removeModule(item.module._id)" v-if="item.status == 1" class="remove-module ml-1">X</span>
              </div>
            </div>
          </div>
          <button class="btn btn-secondary" @click="closeEditWorkspace">
            Cancel
          </button>
        </div>
      </b-sidebar>
    </div>
  </template>
  <script>
  import { BTabs, BTab, BSidebar,BBadge } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  export default {
    data() {
      return {
        userdetails: [],
        user_id: "",
        edit_workspace: false,
        module_details: [],
        tenantid: "",
        modules_list: [],
        role: "",
        user_role_list:[],
        isediting:false,
        module_id:'',
      };
    },
    components: {
      BTabs,
      BTab,
      BSidebar,
      BBadge
    },
    mounted() {
      this.user_id = this.$route.params.id;
      this.getUserDetails();
      
    },
    methods: {
      endEditing(){
        this.isediting = false;
      },
      getRoleListTenant(moduleid) {
      this.isediting = true;
      this.module_id = moduleid;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user-tenant-module/get-all-roles?tenant_id=${this.tenantid}&module_id=${moduleid}&user_id=${this.$route.params.id}`,
      };
      this.$http(options)
        .then((res) => {
          this.user_role_list = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
      updateTenantModule(moduleid,role) {
        // console.log(moduleid + " ->moduleid" + this.user_id +"->userid" + this.tenantid )
        const data = {
          user_id: this.user_id,
          tenant_id: this.tenantid,
          module_id: moduleid,
          role: role,
        };
        const options = {
          method: "POST",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_SSO_API_URL + `/user-tenant-module/update`,
          data: data,
        };
  
        this.$http(options)
          .then((res) => {
              this.getModules();
              this.getUserDetails();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeModule(moduleid){
          const data = {
          user_id: this.user_id,
          tenant_id: this.tenantid,
          module_id: moduleid,
          remove_access: 1,
        };
        const options = {
          method: "POST",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_SSO_API_URL + `/user-tenant-module/update`,
          data: data,
        };
  
        this.$http(options)
          .then((res) => {
              this.getModules();
              this.getUserDetails();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getModuleList() {
        const module_items = [];
        this.module_details.map((item) => {
          module_items.push(item.module);
        });
        this.modules_list = module_items;
      },
      openEditWorkspace(tenantid) {
        this.edit_workspace = true;
        this.tenantid = tenantid;
        this.getModules();
      },
      getModules() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL +
            `/user-tenant-module?user_id=${this.user_id}&tenant_id=${this.tenantid}`,
        };
  
        this.$http(options)
          .then((res) => {
            this.module_details = res.data.data;
            this.getModuleList();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      closeEditWorkspace() {
        this.edit_workspace = false;
      },
      getUserDetails() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL +
            `/user-tenant-module/workspaces?user_id=${this.user_id}`,
        };
  
        this.$http(options)
          .then((res) => {
            this.userdetails = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  <style scoped>
  .remove-module{
      cursor: pointer;
      font-weight: 600;
      color: red;
  }
  </style>
  
  